function setupArchiveTable() {
	let table = $('#archive-table');
	if (table.length > 0) {

		var dt = table.DataTable({
			dom: 'Blfrtip',
			scrollY: "600px",
			scrollX: true,
			scrollCollapse: true,
			colReorder: {
				fixedColumnsLeft: 2
			},
		});
	}
}


function clearArchiveTable() {
	$('#archive-table').DataTable().destroy();
}


document.addEventListener("turbolinks:before-cache", clearArchiveTable);
document.addEventListener('turbolinks:load', setupArchiveTable);
