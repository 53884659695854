function setupStaffTable() {
  var table = $('#staff-table');
  var dt = table.DataTable({
    dom: 'Blfrtip',
    paginate: false,
    scrollY: "600px",
    scrollX: true,
    scrollCollapse: true,
    colReorder: {
      fixedColumnsLeft: 2
    },
    columnDefs: [
      {
        orderable: false,
        className: 'select-checkbox',
        targets: 0,
      }
    ],
    select: {
      style: 'os',
      selector: 'td:first-child'
    }
  });
  setTimeout(() => {
    $(window).trigger('resize');
  }, 1);
  
  // Select All Button
  if (document.getElementById("select_all_staff_btn")) {

    document.getElementById("select_all_staff_btn").addEventListener('click', (event) => {
      selectAll(event.target, dt)
    });
  }

  // Send Mail to Selected
  if (document.getElementById('mail-form')) {
    document.getElementById('mail-form').addEventListener('submit', (event) => {
      event.preventDefault();
      selectedIds= [];
      Array.from(getSelectedRows(dt).data()).forEach(element => {
        selectedIds.push(element[1]);
      });
      rails_input = document.getElementById('selected_ids')
      rails_input.value = selectedIds;
    } )
  }
}


function clearStaffTable() {
  $('#staff-table').DataTable().destroy();
}

function selectAll(btn, dt) {
  if (btn.checked) {
    dt.rows().select();
  } else {
    dt.rows().deselect();
  }
}

function getSelectedRows(dt) {
  return dt.rows( {selected: true});
}

document.addEventListener("turbolinks:before-cache", clearStaffTable);
document.addEventListener('turbolinks:load', setupStaffTable);
