function setup() {
  if(document.getElementById('no_place')) {  
    document.getElementById('no_place').addEventListener('click', function() {
      document.getElementById('block_select').value = null;
      document.getElementById('row_select').value = null;
      document.getElementById('place').toggleAttribute("hidden");
    })
  }
}

document.addEventListener("turbolinks:load", setup);