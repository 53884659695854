function setupNewStaffTable() {
  var table = $('#new_staff_table');
  table.DataTable({
    paginate: false,
    scrollY: "600px",
    scrollX: true,
    scrollCollapse: true,
  });
}

function clearNewStaffTable() {
  $('#new_staff_table').DataTable().destroy();
}

document.addEventListener("turbolinks:before-cache", clearNewStaffTable);
document.addEventListener('turbolinks:load', setupNewStaffTable);
