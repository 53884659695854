function setupExamTable() {
  $('#exams-table').DataTable({
    paginate: false,
    scrollY: "600px",
    scrollX: true,
    scrollCollapse: true,
  });
}

function clearExamTable() {
  $('#exam-table').DataTable().destroy();
}

document.addEventListener("turbolinks:before-cache", clearExamTable);
document.addEventListener('turbolinks:load', setupExamTable);
