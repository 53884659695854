function setup() {
  if(document.getElementById('ares_label_btn')) {
    document.getElementById('ares_label_btn').addEventListener('click', function() {
      document.location.reload(true);
      console.log("test");
    });
    document.getElementById('bp_label_btn').addEventListener('click', function() {
      console.log("test2");
      location.reload();
    });
  }
}

document.addEventListener('turbolinks:load', setup);